
import './assets/js/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css'
import './assets/js/plugins/bootstrap-colorpicker/css/bootstrap-colorpicker.min.css'
import './assets/js/plugins/select2/css/select2.min.css'
import './assets/js/plugins/jquery-tags-input/jquery.tagsinput.min.css'
import './assets/js/plugins/jquery-auto-complete/jquery.auto-complete.min.css'
import './assets/js/plugins/ion-rangeslider/css/ion.rangeSlider.css'
import './assets/js/plugins/ion-rangeslider/css/ion.rangeSlider.skinHTML5.css'
import './assets/js/plugins/dropzonejs/dist/dropzone.css'
import './assets/js/plugins/sweetalert2/sweetalert2.min.css'
import './assets/js/plugins/slick/slick.css'
import './assets/js/plugins/slick/slick-theme.css'

import './assets/_scss/main.scss'

import 'remixicon/fonts/remixicon.css'
import 'simplemde/dist/simplemde.min.css'

window.jQuery = window.$ = require('jquery')


require('./utils')

require('jquery-mask-plugin')
require('bootstrap')

require('./assets/js/core/jquery.slimscroll.min.js')
require('./assets/js/core/jquery-scrollLock.min.js')
require('./assets/js/core/jquery.appear.min.js')
require('./assets/js/core/jquery.countTo.min.js')
require('./assets/js/core/js.cookie.min.js')
require('./assets/js/codebase.js')

require('./assets/js/plugins/bootstrap-datepicker/js/bootstrap-datepicker.min.js')
require('./assets/js/plugins/bootstrap-colorpicker/js/bootstrap-colorpicker.min.js')
require('./assets/js/plugins/bootstrap-maxlength/bootstrap-maxlength.min.js')
require('./assets/js/plugins/jquery-tags-input/jquery.tagsinput.min.js')
require('./assets/js/plugins/jquery-auto-complete/jquery.auto-complete.min.js')
require('./assets/js/plugins/ion-rangeslider/js/ion.rangeSlider.min.js')
require('./assets/js/plugins/dropzonejs/dropzone.min.js')
require('./assets/js/plugins/pwstrength-bootstrap/pwstrength-bootstrap.min.js')

require('./assets/js/plugins/slick/slick.min.js')


