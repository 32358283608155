

const SimpleMDE = require('simplemde')
const select2 = require('./assets/js/plugins/select2/js/select2.full.js')
const sweetalert2 = require('./assets/js/plugins/sweetalert2/sweetalert2.js')

window.SimpleMDE = SimpleMDE

window.sweetalert2 = sweetalert2

window.$success = sweetalert2.mixin({
    toast: true,
    backdrop: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    type: 'success'
})

window.$error = sweetalert2.mixin({
    toast: true,
    backdrop: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    type: 'error'
})

window.$info = sweetalert2.mixin({
    toast: true,
    backdrop: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    type: 'info'
})

window.$warning = sweetalert2.mixin({
    toast: true,
    backdrop: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    type: 'warning'
})

window.$confirm = sweetalert2.mixin({
    type: 'warning',
    backdrop: true,
    showCancelButton: true,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',    
    buttonsStyling: false,
    confirmButtonClass: 'btn btn-lg btn-alt-primary m-5',
    cancelButtonClass: 'btn btn-lg btn-alt-secondary m-5',
    inputClass: 'form-control'
})


function QueryStringToJSON() {            
    var pairs = location.search.slice(1).split('&');
    
    var result = {};
    pairs.forEach(function(pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
}

window.queryString = QueryStringToJSON

select2()
